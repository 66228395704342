export default {
    data: function () {
        return {
            tempDetail: {
                title:'',
                ctime:'',
                content:'',
                url:''
            }
        }
    },
    created() {
        this.getTempDetail(this.$route.query.id)
    },
    methods: {
        getTempDetail: function (id) {
            this.$post('/api/index/tempDetail.html', {
                id:id
            }).then((res) => {
                if (res.code == 200) {
                    this.tempDetail = res.data || {}
                } else {
                    return this.$message(res.msg);
                }
            }).finally(() => {

            })
        },
    }
}