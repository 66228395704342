<template>
  <div class="news-detail">
    <div class="c-width">
      <pwd pwdText="新闻资讯" :current="tempDetail.title" />
      <div class="context-box">
        <h3>{{tempDetail.title}}</h3>
        <img class="new-img" :src="tempDetail.url" alt="">
        <div class="text" v-html="tempDetail.content"></div>
      </div>
    </div>
  </div>
</template>
<script>
import mixins from "../mixins";
import pwd from "@/component/pwd/src/pwd";
export default {
  mixins,
  components: {
    pwd,
  },
};
</script>
<style scoped>
@import "../news-detail.css"
</style>